<template>
	<BasePageLayout>
		<div slot="page__title">Thống kê dữ liệu</div>
		<div class="pa-8">
			<TableStatistic :items="statistic" />
		</div>
	</BasePageLayout>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import BasePageLayout from '@/templates/layout/BasePageLayout'
import TableStatistic from '@/modules/Statistic/TableStatistic'
import dayjs from 'dayjs'
export default {
	components: {
		BasePageLayout,
		TableStatistic,
	},
	computed: {
		...mapGetters({
			statistic: 'statistic/getStatistic'
		})
	},
	methods: {
		...mapActions({
			fetchStatistic: 'statistic/fetchStatistic'
		}),
	},
	async mounted() {
		this.$message.loading('Đang lấy dữ liệu...')

		await this.fetchStatistic({
			filter_time: `${dayjs()
				.startOf('month')
				.format('DD/MM/YYYY')} 00:00:01 - ${dayjs()
				.endOf('month')
				.format('DD/MM/YYYY')} 23:59:59`,
			group_id: 77,
			site_ids: [453, 454]
		})

		this.$message.close()
	}
}
</script>
