<template>
	<v-data-table
		class="has-border"
		disable-sort
		:headers="headers"
		:items="listItems"
		:search="search"
	>
		<template v-slot:[`item.domain`]="{item}">
			{{ item.Referer || 'No Referer' }}
		</template>
		<template v-slot:[`item.bandwidth`]="{item}">
			{{ item.Bandwidth }}
		</template>
		<template v-slot:[`item.requestTime`]="{item}">
			{{ item.Request }}
		</template>
	</v-data-table>
</template>
<script>
import moment from 'moment'
export default {
	props: {
		items: Array,
		search: String,
	},
	computed: {
		listItems() {
			if (!this.items) return []
			return this.items.map(item => {
				return {
					...item,
					Bandwidth: this.$utils.formatBytes(item.Bandwidth)
				}
			})
		}
	},
	data() {
		return {
			moment,
			headers: [
				{text: 'Domain', value: 'domain', width: '20%'},
				{
					text: 'Dung lượng sử dụng',
					value: 'bandwidth',
					width: '15%',
					align: 'center'
				},
				{
					text: 'Số lần request',
					value: 'requestTime',
					width: '5%',
					align: 'right'
				}
			]
		}
	}
}
</script>
